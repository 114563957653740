/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useMemo, useRef } from 'react'
import { useGLTF, Reflector } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'

import { useTrimesh } from '@react-three/cannon'
import img from './wood.jpg'

const material = new THREE.MeshPhysicalMaterial({
  color: new THREE.Color('#bb86a1').convertSRGBToLinear(),
  roughness: 0,
  clearcoat: 1,
  clearcoatRoughness: 0,
})

export default function Model(props) {
  const { nodes } = useGLTF( 
    '/scene.gltf'
  )

  const texture = useLoader(THREE.TextureLoader, img)

  const [ref, api] = useTrimesh(
    () => ({
      args: [
        props.version == 0 ? nodes.mesh_0.geometry.attributes.position.array : nodes.mesh_1.geometry.attributes.position.array,
        props.version == 0 ? nodes.mesh_0.geometry.index.array : nodes.mesh_1.geometry.index.array,
      ],
      type: 'Static',
      mass: 100,
      fixedRotation: true,
      ...props,
    }),
    useRef()
  );

  return (
    <group
    name='crone'
      ref={ref}
      {...props}
      dispose={null}
    >
      <mesh
        map={texture}
        geometry={props.version == 0 ? nodes.mesh_0.geometry : nodes.mesh_1.geometry}
      >
      <meshPhysicalMaterial attach="material" map={texture} />
      </mesh>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
