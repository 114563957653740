import * as THREE from 'three'
import { Suspense, useRef, useState } from 'react'
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, CameraShake, Environment } from '@react-three/drei'
import Model from './Model'
import { degToRad } from 'three/src/math/MathUtils'

import { Physics } from "@react-three/cannon";
import Ball from './Ball'
import Ground from './Ground'

RectAreaLightUniformsLib.init() 

function Light() {
  const ref = useRef()
  useFrame((_) => (ref.current.rotation.x = _.clock.elapsedTime))
  return (
    <group ref={ref}>
      <rectAreaLight width={15} height={100} position={[30, 30, -10]} intensity={5} onUpdate={(self) => self.lookAt(0, 0, 0)} />
    </group>
  )
}

function Rig() {
  const [vec] = useState(() => new THREE.Vector3())
  const { camera, mouse } = useThree()
  useFrame(() => camera.position.lerp(vec.set(mouse.x * 2, 2, 60), 0.05))
  return <CameraShake maxYaw={0.01} maxPitch={0.01} maxRoll={0.01} yawFrequency={0.5} pitchFrequency={0.85} rollFrequency={0.94} />
}

export default function App() {
  
  const [fireBall1, setFireBall1] = useState(false);
  const [fireBall2, setFireBall2] = useState(false);
  const [fireBall3, setFireBall3] = useState(false);
  const [fireBall4, setFireBall4] = useState(false);
  const [fireBall5, setFireBall5] = useState(false);
  const [fireBall6, setFireBall6] = useState(false);

  const velocity = [0, 8, -50];
  return (
    <div style={{ width: '100vw', height: '100vh'}}>
    <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 420, 100], fov: 28 }} style={{ position: 'relative', zIndex: 10 }} onClick={() => {
      if(fireBall1 == false) { setFireBall1(true); }
      else if(fireBall2 == false) { setFireBall2(true); }
      else if(fireBall3 == false) { setFireBall3(true); }
      else if(fireBall4 == false) { setFireBall4(true); }
      else if(fireBall5 == false) { setFireBall5(true); }
      else if(fireBall6 == false) { setFireBall6(true); }
    }}>
      <fog attach="fog" args={['#6b705c', 60, 100]} />
      <Suspense fallback={null}>
        <Physics>
          {fireBall1 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {fireBall2 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {fireBall3 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {fireBall4 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {fireBall5 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {fireBall6 && (
            <>
              <Ball position={[0, 1, 50]} color={"#111"} velocity={velocity} />
            </>
          )}
          {/* <Ground rotation={[-Math.PI / 2, 0, 0]} /> */}
          <Model version={0} position={[1.85, -9, 20]} scale={[1.65, 1, 1]} rotation={[degToRad(20), degToRad(200), degToRad(-11)]}/>
          <Model version={1} position={[1.85, -9, 20]} scale={[1.65, 1, 1]} rotation={[degToRad(20), degToRad(200), degToRad(-11)]}/>
        </Physics>
        <ambientLight intensity={0.5} />
        
        {/* <spotLight position={[50, 50, -30]} castShadow />
        <pointLight position={[-10, -10, -10]} color="#6b705c" intensity={3} />
        <pointLight position={[0, -5, 5]} intensity={0.5} />
        <directionalLight position={[0, -5, 0]} color="#6b705c" intensity={2} /> */}
        <Light />
        <Environment preset="warehouse" />
        <Rig />
      </Suspense>
      <OrbitControls makeDefault enableZoom={false} />
    </Canvas>
    
    <img src="../PTNOTC_LOGO.png" style={{ pointerEvents: 'none', position: 'relative', zIndex: 11, left: '50%', transform: 'translateX(-50%)', opacity: 0.6, width: '70vw', maxWidth: '300px', position: 'absolute', bottom: '1.8rem'}} />

    <div id='background-img' style={{top: 0, left: 0, backgroundImage: 'url(../woods_background.jpeg)', opacity: 0.4, width: '100vw', height: '100vh', position: 'absolute', backgroundSize: 'cover', backgroundPosition: 'center top' }} />

    <p style={{ color: '#fff', filter: 'drop-shadow(1px 1px 1px black)', pointerEvents: 'none', fontSize: '1.2rem', fontFamily: 'Candara,Calibri,Segoe,Segoe UI,Optima,Arial,sans-serif', letterSpacing: '0.95px', fontWeight: 'bold', left: '50%', transform: 'translateX(-50%)', opacity: 0.4, width: '100%', textAlign: 'center', position: 'absolute', top: '0.8rem', lineHeight: '1rem'}}>TAP TO PIN THE NAILS<br/><span style={{ fontSize: '0.7rem' }}>REFRESH TO REPLAY</span></p>
    </div>
  )
}


