import * as THREE from "three";
import React, { useState } from "react";
import { useLoader, useThree } from '@react-three/fiber'
import { useSphere } from "@react-three/cannon";
import { degToRad } from "three/src/math/MathUtils";

const Ball = ({ position, color, velocity }) => {

    const { camera, mouse } = useThree()

    const [type, setType] = useState('Dynamic');
    const [mass, setMass] = useState(6);

    const [ballRef, api] = useSphere(() => ({ rotation: [0,degToRad(0),0], position: [0, 0, 12], velocity: [mouse.x * (Math.random() * (80 - 20) + 20), velocity[1] + mouse.y * (Math.random() * (30 - 5) + 5), velocity[2]], mass: mass, type: type, position: position, onCollideBegin: (e) => {
        if( e.body.name == 'crone') {
            api.sleep();
        }
    } 
}));

return (
    <group ref={ballRef} position={position}>
        <mesh position={[0, -0.12, 0.65]} scale={[1, 1, .2]}>
            <sphereGeometry args={[.2, 10, 4]} />
            <meshPhysicalMaterial
                color={color}
                // map={bw}
                roughness={1.2}
                metalness={0.2}
                clearcoat={0.6}
                clearcoatRoughness={0.35}
            />
        </mesh>
        <mesh rotation={[degToRad(100),0,0]}>
            <cylinderGeometry args={[.09,.09,1.3,3]} />
            <meshPhysicalMaterial
                color={color}
                // map={bw}
                roughness={1.2}
                metalness={0.2}
                clearcoat={0.6}
                clearcoatRoughness={0.35}
            />
        </mesh>
    </group>
  );
};

export default Ball;
